import styled from 'styled-components'

const CustomPagination = styled.div`
  .swiper-pagination-bullet  {
    background: white;
  }
  .swiper-pagination-bullet-active {
    background: ${props => props.theme.green};
  }
`

const NavigationButton = styled.button`
  background-image: none;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.textLight};
  padding: 0;
  &:focus {
    outline: none;
  }
`

export { CustomPagination, NavigationButton }
