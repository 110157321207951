import React from 'react'
import { CustomPagination } from './Carousel.styled'
import Swiper from 'react-id-swiper'

const Carousel = ({ children, ...props }) => {
  const params = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // renderPrevButton: () => (
    //   <NavigationButton className="swiper-button-prev">
    //     <Icon icon="chevron-left" size="3x" />
    //   </NavigationButton>
    // ),
    // renderNextButton: () => (
    //   <NavigationButton className="swiper-button-next">
    //     <Icon icon="chevron-right" size="3x" />
    //   </NavigationButton>
    // ),
    renderPagination: () => (
      <CustomPagination className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets" />
    ),
  }
  return (
    <Swiper
      {...params}
      {...props}
      style={{ maxHeight: '600px', overflow: 'hidden' }}
    >
      {children}
    </Swiper>
  )
}

export default Carousel
